@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Satoshi", sans-serif;
}

::-webkit-scrollbar{
  display: none;
}
.desktop-nav .active{
  color: #1FBE58;
  border-bottom-color: #1FBE58;
}
.mobile-nav .active{
  color:#1FBE58;
  background:#F0F0F0;
}
.navigation__checkbox {
  display: none;
}

.navigation__icon{
  width: 1rem;
  height: 2px;
  background-color: #000;
  display: inline-block;
}
.navigation__icon::before,
.navigation__icon::after {
  width: 1.5rem;
  height: 2px;
  background-color: #000;
  display: inline-block;
 
}
.navigation__icon::before,
.navigation__icon::after {
  content: "";
  position: absolute;
  right: 0;
  transition: all 0.2s;
}
.navigation__icon::before {
  top: -0.4rem;
}
.navigation__icon::after {
  top: 0.4rem;
}
.navigation__button:hover .navigation__icon::before {
  top: -0.45rem;
}
.navigation__button:hover .navigation__icon::after {
  top: 0.45rem;
}
.navigation__checkbox:checked + .navigation__button .navigation__icon {
  background-color: transparent;
}
.navigation__checkbox:checked + .navigation__button .navigation__icon:after {
  top: 0;
  transform: rotate(-135deg);
}
.navigation__checkbox:checked + .navigation__button .navigation__icon:before {
  top: 0;
  transform: rotate(135deg);
}